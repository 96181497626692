import { useEffect, useState } from "react";
import axios from "axios";
import * as localizedFormat from "dayjs/plugin/localizedFormat";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { DateRangePicker } from "react-date-range";
import { Card, CardHead } from "../../components/common/global/global";
import { PageWrapper } from "../../components/common/global/global";
import Pagename from "../../components/common/pagename/page-name";
import Pagination from "../../components/common/pagination/pagination";
import { Wrapper } from "./sales-by-products.e";
import { Flex, Relative } from "../reports/reports.e";
import Button from "../../components/common/button/button";
import { Radio, RadioButtons } from "../inventory/inventory.e";
dayjs.extend(localizedFormat);

function SalesByProducts() {
  const [products, setProducts] = useState();
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState("name");
  const [sortType, setSortType] = useState("1");
  const [date, setDate] = useState([
    {
      startDate: new Date().setDate(new Date().getDate() - 6),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [datePicker, setDatePicker] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    axios
      .get(
        `https://dev.in1.uz/api/invan-supplier/report/sales/by_item/below/${dayjs(
          date[0].startDate
        ).valueOf()}/${
          dayjs(date[0].endDate).valueOf() + 86399999
        }?page=${page}&sort_by=${sort}&sort_type=${sortType}`,
        {
          headers: {
            Authorization: `${localStorage.getItem("token")}`,
            "Accept-user": "admin",
            "content-type": "application/json",
            "Accept-version": "1.0.0",
          },
        }
      )
      .then((response) => {
        setProducts(response.data.data);
      });
  }, [date, page, sort, sortType]);

  return (
    <PageWrapper>
      <Pagename text={t("sales_by_products")} />
      <Wrapper>
        <Card>
          <CardHead>
            <Flex style={{ marginBottom: "20px" }}>
              <h6>{t("sales_by_products")}</h6>
              <Flex className="w-600">
                <RadioButtons>
                  <Radio
                    className={sort === "name" ? "active" : ""}
                    onClick={() => setSort("name")}
                  >
                    {t("by_name")}
                  </Radio>
                  <Radio
                    className={sort === "items_sold" ? "active" : ""}
                    onClick={() => setSort("items_sold")}
                  >
                    {t("sales")}
                  </Radio>
                </RadioButtons>
                <RadioButtons>
                  <Radio
                    className={sortType === "1" ? "active" : ""}
                    onClick={() => setSortType("1")}
                  >
                    {t("az")}
                  </Radio>
                  <Radio
                    className={sortType === "-1" ? "active" : ""}
                    onClick={() => setSortType("-1")}
                  >
                    {t("za")}
                  </Radio>
                </RadioButtons>
              </Flex>
              <Button
                onClick={() => setDatePicker(!datePicker)}
                soft="primary"
                text={`${dayjs(date[0].startDate).format("MMM DD")} to ${dayjs(
                  date[0].endDate
                ).format("MMM DD")}`}
              />
            </Flex>
            {datePicker && (
              <Relative>
                <DateRangePicker
                  className="date"
                  onChange={(item) => setDate([item.selection])}
                  showSelectionPreview={true}
                  moveRangeOnFirstSelection={false}
                  months={1}
                  ranges={date}
                  direction="horizontal"
                  maxDate={new Date()}
                />
              </Relative>
            )}
            <table>
              <thead>
                <tr>
                  <th width="60%">{t("name_of_product")}</th>
                  <th width="20%">{t("sales")}</th>
                  <th width="13%">{t("sales_count")}</th>
                </tr>
              </thead>
              <tbody>
                {products?.data?.map((item, i) => {
                  return (
                    <tr key={i}>
                      <td style={{ paddingRight: "10px" }} width="60%">
                        <p>{item.name}</p>
                      </td>
                      <td width="20%">
                        <p>{item.gross_sales.toLocaleString()}</p>
                      </td>
                      <td width="8%" className="w-700">
                        <p className="green">{item.items_sold}</p>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </CardHead>
        </Card>
        {products?.data && (
          <Pagination
            current={page}
            total={Math.round(products?.page_count)}
            next={() => setPage(page + 1)}
            prev={() => setPage(page - 1)}
            onChange={setPage}
          />
        )}
      </Wrapper>
    </PageWrapper>
  );
}

export default SalesByProducts;
