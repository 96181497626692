import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-right: 20px;
  margin-right: 10px;
  position: relative;
`;

export const Droppdown = styled.div`
  padding: 16px;
  border-radius: 12px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  position: absolute;
  bottom: -60px;
  left: -100px;
  width: 150px;
  background-color: #fff;
  font-size: 14px;
`;

export const DropdownItem = styled.div`
  display: flex;
  align-items: center;
  i {
    margin-right: 15px !important;
    transform: translateY(2px);
  }
`;

export const Avatar = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  background-color: var(--thin-gray);
  color: rgb(255, 255, 255);
  font-weight: 700;
  border-radius: 50%;
  margin-right: 8px;
`;

export const Name = styled.div`
  color: var(--main);
  p {
    font-size: 14px;
    margin-top: 2px;
  }
  span {
    font-size: 12px;
    color: var(--gray);
  }
  @media (max-width: 800px) {
    display: none;
  }
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
  .fa-arrow-right-from-bracket {
    margin-right: 30px;
    font-size: 20px;
    color: var(--gray);
    cursor: pointer;
  }
`;
