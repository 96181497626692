import styled from "styled-components";

export const Wrapper = styled.div`
  justify-content: flex-end;
  padding: 18px;
  display: flex;
  align-items: center;
  margin-top: 40px;
  input {
    background-color: transparent;
  }
`;

export const FlexContainer = styled.div`
  width: 270px;
  color: #878a99;
  display: flex;
  align-items: center;
  justify-content: space-between;
  input {
    width: 60px;
    border: 1px solid var(--thin-gray);
    padding: 0.5rem 0.9rem;
    border-radius: 4px;
    color: var(--main);
  }
`;
