import styled from "styled-components";

export const Wrapper = styled.div`
  margin-bottom: 10px;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 0.5px;
  font-weight: 600;
  color: var(--blue);
  a {
    color: var(--main);
    transition: 0.15s all ease-in-out;
    &:active {
      color: var(--main);
    }
    &:hover {
      color: var(--blue);
    }
  }
  i {
    margin: 0 8px;
  }
`;
