import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Wrapper } from "./page-name.e";

function Pagename({ text }) {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <p>
        <Link to="/">{t("dashboard")}</Link>
        <i>/ </i>
        {text}
      </p>
    </Wrapper>
  );
}

export default Pagename;
