import { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import { DateRangePicker } from "react-date-range";
import * as localizedFormat from "dayjs/plugin/localizedFormat";
import dayjs from "dayjs";
import axios from "axios";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import Pagename from "../../components/common/pagename/page-name";
import Button from "../../components/common/button/button";
import { Flex, Relative } from "./reports.e";
import {
  CardBody,
  Card,
  PageWrapper,
  CardHead,
} from "../../components/common/global/global";
dayjs.extend(localizedFormat);

function Reports() {
  const [data, setData] = useState();
  const [totals, setTotals] = useState();
  const [date, setDate] = useState([
    {
      startDate: new Date().setDate(new Date().getDate() - 6),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [datePicker, setDatePicker] = useState(false);
  const report_data = {
    date: [],
    sales: [],
  };
  const { t } = useTranslation();

  data?.data?.map((item) => {
    report_data.date.push(item.date);
    report_data.sales.push(item.net_sales);
  });

  const differenceDay = dayjs(date[0].endDate).diff(
    dayjs(date[0].startDate),
    "day"
  );

  useEffect(() => {
    axios
      .post(
        `https://dev.in1.uz/api/invan-supplier/dashboard/${dayjs(
          date[0].startDate
        ).valueOf()}/${dayjs(date[0].endDate).valueOf() + 86399999}/2000/1`,
        {
          custom: false,
          start: 1,
          end: 86399999,
          services: [],
          employees: [],
          search: "search",
          target: "net_sales",
          count_type:
            differenceDay <= 1
              ? 1
              : differenceDay <= 7
              ? 2
              : differenceDay <= 31
              ? 3
              : differenceDay <= 62
              ? 4
              : differenceDay <= 90
              ? 5
              : 6,
        },
        {
          headers: {
            Authorization: `${localStorage.getItem("token")}`,
            "content-type": "application/json",
            "Accept-version": "1.0.0",
          },
        }
      )
      .then((response) => {
        setData(response.data);
      });

    axios
      .post(
        `https://dev.in1.uz/api/invan-supplier/dashboard/${dayjs(
          date[0].startDate
        ).valueOf()}/${dayjs(date[0].endDate).valueOf() + 86399999}`,
        {
          custom: false,
          start: 1,
          end: 86399999,
          services: [],
          employees: [],
          search: "search",
          target: "net_sales",
          count_type:
            differenceDay <= 1
              ? 1
              : differenceDay <= 7
              ? 2
              : differenceDay <= 31
              ? 3
              : differenceDay <= 62
              ? 4
              : differenceDay <= 90
              ? 5
              : 6,
        },
        {
          headers: {
            Authorization: `${localStorage.getItem("token")}`,
            "content-type": "application/json",
            "Accept-version": "1.0.0",
          },
        }
      )
      .then((response) => {
        setTotals(response.data);
      });
  }, [date]);

  const card = {
    series: [
      {
        data: [
          16, 10, 12, 11, 9, 7, 9.11, 7, 11, 10, 13, 12, 14, 10, 9, 12, 10, 8,
          12,
        ],
      },
    ],
    options: {
      chart: {
        stacked: false,
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      colors: ["#f8fafb"],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        colors: ["#dee"],
      },
      markers: {
        size: 0,
      },
      xaxis: {
        labels: {
          show: false,
        },
      },
      yaxis: {
        labels: {
          show: false,
        },
      },
      grid: {
        show: false, // you can either change hear to disable all grids
        xaxis: {
          lines: {
            show: true, //or just here to disable only x axis grids
          },
        },
        yaxis: {
          lines: {
            show: true, //or just here to disable only y axis
          },
        },
      },
    },
  };

  const sales = {
    series: [
      {
        name: "Net Sales",
        data: report_data?.sales,
      },
    ],
    options: {
      chart: {
        type: "area",
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      xaxis: {
        type: "datetime",
        categories: report_data?.date,
      },
      tooltip: {
        x: {
          format: "dd/MM/yy HH:mm",
        },
      },
      noData: {
        text: "Maybe there are no orders ...",
      },
      colors: ["#3577f1"],
    },
  };

  return (
    <PageWrapper>
      <Pagename text={t("sales_monitoring")} />
      <Flex>
        <h4>{t("welcome_to_dashboard")}</h4>
        <Button
          onClick={() => setDatePicker(!datePicker)}
          soft="primary"
          text={`${dayjs(date[0].startDate).format("MMM DD")} to ${dayjs(
            date[0].endDate
          ).format("MMM DD")}`}
        />
      </Flex>
      {datePicker && (
        <Relative>
          <DateRangePicker
            className="date"
            onChange={(item) => setDate([item.selection])}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={1}
            ranges={date}
            direction="horizontal"
            maxDate={new Date()}
          />
        </Relative>
      )}
      {/* <Grid4>
        {totals && (
          <>
            <Card className="chart">
              <CardBody>
                <h6>Net Sales</h6>
                <Count>
                  <h3>{totals?.net_sales.toLocaleString()}</h3>
                  <p>
                    <span>
                      1.2% <i className="fa fa-arrow-up" />
                    </span>
                    than last week
                  </p>
                </Count>
                <Absolute>
                  <ReactApexChart
                    options={card.options}
                    series={card.series}
                    type="area"
                    height={150}
                  />
                </Absolute>
              </CardBody>
            </Card>
            <Card className="chart">
              <CardBody>
                <h6>Gross Sales</h6>
                <Count>
                  <h3>{totals?.gross_sales?.toLocaleString()}</h3>
                  <p>
                    <span>
                      1.2% <i className="fa fa-arrow-up" />
                    </span>
                    than last week
                  </p>
                </Count>
                <Absolute>
                  <ReactApexChart
                    options={card.options}
                    series={card.series}
                    type="area"
                    height={150}
                  />
                </Absolute>
              </CardBody>
            </Card>
            <Card className="chart">
              <CardBody>
                <h6>Cost of Goods</h6>
                <Count>
                  <h3>{Math.round(totals?.cost_of_goods).toLocaleString()}</h3>
                  <p>
                    <span>
                      1.2% <i className="fa fa-arrow-up" />
                    </span>
                    than last week
                  </p>
                </Count>
                <Absolute>
                  <ReactApexChart
                    options={card.options}
                    series={card.series}
                    type="area"
                    height={150}
                  />
                </Absolute>
              </CardBody>
            </Card>
            <Card className="chart">
              <CardBody>
                <h6>Refunds</h6>
                <Count>
                  <h3>{totals?.refunds?.toLocaleString()}</h3>
                </Count>
                <Absolute>
                  <ReactApexChart
                    options={card.options}
                    series={card.series}
                    type="area"
                    height={150}
                  />
                </Absolute>
              </CardBody>
            </Card>
          </>
        )}
      </Grid4> */}
      {report_data.date && (
        <Card>
          <CardHead>
            <ReactApexChart
              options={sales.options}
              series={sales.series}
              type="area"
              width="98%"
              height={300}
            />
          </CardHead>
        </Card>
      )}
      {data && (
        <Card>
          <CardBody>
            {data && (
              <table>
                <thead>
                  <tr style={{ border: "none", paddingTop: 0 }}>
                    <th width="29%">{t("date")}</th>
                    <th width="26%">{t("net_sales")}</th>
                    <th width="28%">{t("gross_sales")}</th>
                    <th width="18%">{t("refunds")}</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.data?.map((i) => {
                    return (
                      <tr key={i?._id}>
                        <td width="29%">
                          <p>{dayjs(i?.date).format("MMM DD, YYYY")}</p>
                        </td>
                        <td className="green" width="25%">
                          {Math.round(i?.net_sales)?.toLocaleString()}
                        </td>
                        <td width="28%">
                          {Math.round(i?.gross_sales)?.toLocaleString()}
                        </td>
                        <td className="red" width="18%">
                          {i?.refunds?.toLocaleString()}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </CardBody>
        </Card>
      )}
    </PageWrapper>
  );
}

export default Reports;
