import { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Wrapper,
  MenuButton,
  LeftMenu,
  MenuContent,
  Overlay,
  MenuContentWrapper,
  MainNavigation,
} from "./header.e";
import Profile from "./profile/profile";
import Logo from "../logo/logo";

function Header() {
  const [menu, setMenu] = useState(false);
  const { t } = useTranslation();
  return (
    <Wrapper>
      <MenuButton onClick={() => setMenu(true)}>
        <i className="fa-solid fa-bars" />
      </MenuButton>
      <Logo forHeader={true} />
      <Profile />
      {menu && (
        <LeftMenu>
          <MenuContent>
            <MenuContentWrapper>
              <div onClick={() => setMenu(false)}>
                <Logo />
              </div>
              <MainNavigation>
                <p>Main Navigation</p>
                <Link to="/" onClick={() => setMenu(false)}>
                  <i className="fa-solid fa-chart-pie-simple" />{" "}
                  {t("dashboard")}
                </Link>
                <Link to="/inventory" onClick={() => setMenu(false)}>
                  <i className="fa-solid fa-cube" /> {t("inventory")}
                </Link>
                <Link to="/sales-by-products" onClick={() => setMenu(false)}>
                  <i className="fa-solid fa-square-kanban" />{" "}
                  {t("sales_by_products")}
                </Link>
                <Link to="/documents" onClick={() => setMenu(false)}>
                  <i className="fa-solid fa-file-lines" /> {t("documents")}
                </Link>
                <Link to="/settings" onClick={() => setMenu(false)}>
                  <i className="fa-solid fa-gear" /> {t("settings")}
                </Link>
              </MainNavigation>
            </MenuContentWrapper>
          </MenuContent>
          <Overlay onClick={() => setMenu(false)} />
        </LeftMenu>
      )}
    </Wrapper>
  );
}

export default Header;
