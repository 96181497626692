import { useEffect, useState } from "react";
import axios from "axios";
import * as localizedFormat from "dayjs/plugin/localizedFormat";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import Loader from "../common/loader/loader";
import Pagination from "../common/pagination/pagination";
import { Wrapper } from "./transactions.e";
import { Card, CardHead } from "../common/global/global";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
dayjs.extend(localizedFormat);

function Transactions() {
  const [page, setPage] = useState(1);
  const [data, seTdata] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    axios
      .get(
        `https://dev.in1.uz/api/invan-supplier/supplier/transactions?page=${page}`,
        {
          headers: {
            Authorization: `${localStorage.getItem("token")}`,
            "content-type": "application/json",
            "Accept-version": "1.0.0",
          },
        }
      )
      .then((response) => {
        seTdata(response.data);
      })
      .catch((error) => {
        alert(error?.message);
      });
  }, [page]);

  const getAll = () => {
    axios
      .get(
        `https://dev.in1.uz/api/invan-supplier/supplier/transactions?limit=${data?.total}`,
        {
          headers: {
            Authorization: `${localStorage.getItem("token")}`,
            "content-type": "application/json",
            "Accept-version": "1.0.0",
          },
        }
      )
      .then((response) => {
        let custom = [];

        response.data.data.map((item, i) => {
          custom[i] = {
            Document: item.document_id,
            Date: dayjs(item?.date).format("DD.MM.YY, HH:mm"),
            "Employee Name": item.employee_name,
            Status: item.status,
            "Balance Type": item.balance_type,
            Credit: item.balance > 0 ? item.balance.toLocaleString() : "-",
            Debt: item.balance < 0 ? item.balance.toLocaleString() : "-",
          };
        });
        exportToCSV(custom, "test");
      })

      .catch((error) => {
        alert(error?.message);
      });
  };

  const getDocument = (purchase_id, file_name) => {
    axios
      .get(
        `https://dev.in1.uz/api/invan-supplier/inventory/purchase/pdf/${purchase_id}/${file_name}`,
        {
          responseType: "arraybuffer",
          headers: {
            Authorization: `${localStorage.getItem("token")}`,
            "content-type": "application/json",
            "Accept-version": "1.0.0",
          },
        }
      )
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
        var link = document.createElement("a");
        link.href = url;
        link.download = "file.pdf";
        link.dispatchEvent(new MouseEvent("click"));
      });
  };

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = (apiData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(apiData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <>
      {!data.data && <Loader />}
      <Wrapper style={!data.data ? { opacity: 0 } : { opacity: 1 }}>
        <Card>
          <CardHead>
            <h6 onClick={getAll}>
              {t("transaction_history")}
              <i
                onClick={getAll}
                className="fa-solid fa-file-excel downloader"
              />
            </h6>
            <Table>
              <Thead>
                <Tr>
                  <Th width="27%">{t("document")}</Th>
                  <Th width="13%">{t("date")}</Th>
                  <Th width="13%">{t("employee")}</Th>
                  <Th width="9%">{t("status")}</Th>
                  <Th width="11%">{t("balance_type")}</Th>
                  <Th width="13%">{t("credit")}</Th>
                  <Th width="11%">{t("debt")}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {data?.data?.map((t, i) => {
                  return (
                    <Tr key={i}>
                      <Td
                        className={t.document_id.length === 6 ? "url" : ""}
                        onClick={() =>
                          t.document_id.length === 6 &&
                          getDocument(t.purchase_id, t.document_id)
                        }
                        width="27%"
                      >
                        <p>{t.document_id}</p>
                      </Td>
                      <Td width="13%">
                        <p>{dayjs(t?.date).format("DD.MM.YY, HH:mm")}</p>
                      </Td>
                      <Td width="13%">
                        <p>{t.employee_name}</p>
                      </Td>
                      <Td width="9%">
                        <p className="green">{t.status}</p>
                      </Td>
                      <Td width="11%">
                        <p>{t.balance_type}</p>
                      </Td>
                      <Td width="13%">
                        <p>
                          {t.balance > 0 ? t.balance.toLocaleString() : "-"}
                        </p>
                      </Td>
                      <Td width="11%">
                        <p>
                          {t.balance < 0 ? t.balance.toLocaleString() : "-"}
                        </p>
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </CardHead>
        </Card>
        {data.data && (
          <Pagination
            current={page}
            total={Math.round(data?.total / 10)}
            next={() => setPage(page + 1)}
            prev={() => setPage(page - 1)}
            onChange={setPage}
          />
        )}
      </Wrapper>
    </>
  );
}

export default Transactions;
