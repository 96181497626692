import { useTranslation } from "react-i18next";
import { PageWrapper } from "../../components/common/global/global";
import Pagename from "../../components/common/pagename/page-name";
import Transactions from "../../components/transactions/transactions";

function Documents() {
  const { t } = useTranslation();
  return (
    false && (
      <PageWrapper>
        <Pagename text={t("document")} />
        <Transactions />
      </PageWrapper>
    )
  );
}

export default Documents;
