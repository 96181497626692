import styled from "styled-components";

export const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .PhoneInputCountry {
    display: none;
  }
  .PhoneInputInput {
    padding: 18px 0;
    font-size: 14px;
    font-weight: 500;
    transform: translateX(-5px);
  }
  .timer {
    margin-top: 10px;
    color: var(--purple);
  }
  input {
    -webkit-user-select: text;
    user-select: text;
    &.anime {
      animation: 0.6s slideDown;
    }
  }
  @keyframes slideDown {
    0% {
      opacity: 0;
      transform: translateY(10px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

export const AuthContent = styled.div`
  h2 {
    margin-bottom: 20px;
    margin-top: 15px;
    font-weight: 400;
  }
  width: 450px;
  margin: 0 auto;
  @media (max-width: 800px) {
    width: 100%;
    margin: 0;
  }
`;

export const Form = styled.form`
  margin-top: 20px;
  display: grid;
  grid-gap: 8px;
  grid-template-columns: 1fr;
  label {
    margin-bottom: 4px;
  }
`;

export const Phone = styled.div`
  display: flex;
  align-items: center;
  padding: 0 15px;
  border-radius: 12px;
  background-color: #ededed;
  color: var(--main);
  color: var(--main);
  span {
    width: 21%;
    font-weight: 500;
    display: flex;
    align-items: center;
    i {
      transform: translateY(3px);
      font-size: 22px;
      margin-right: 10px;
    }
  }
  @media (max-width: 700px) {
    span {
      width: 30%;
    }
  }
`;
