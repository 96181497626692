import styled from "styled-components";

export const Wrapper = styled.header`
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const MenuButton = styled.div`
  cursor: pointer;
  width: 80px;
  padding: 25px;
  font-size: 20px;
  transition: 0.15s all ease-in-out;
  border-bottom: 1px solid var(--border-color);
  border-right: 1px solid var(--border-color);
  text-align: center;
  color: var(--thin-gray);
  &:hover {
    color: var(--main);
  }
  @media (max-width: 800px) {
    border: none;
  }
`;

export const LeftMenu = styled.div`
  top: 0;
  left: 0;
  position: fixed;
  height: 130vh;
  width: 100%;
  display: flex;
  overflow: hidden;
  z-index: 111;
`;

export const MenuContent = styled.div`
  width: 20%;
  height: 130vh;
  background-color: #fff;
  @media (max-width: 800px) {
    width: 70%;
  }
`;

export const Overlay = styled.div`
  width: 82%;
  height: 130vh;
  background-color: rgba(0, 0, 0, 0.4);
  @media (max-width: 800px) {
    background-color: rgba(0, 0, 0, 0.7);
    width: 30%;
  }
`;

export const MenuContentWrapper = styled.div`
  padding: 20px;
`;

export const MainNavigation = styled.div`
  p {
    color: var(--gray);
    font-weight: 600;
    font-size: 14px;
    padding-top: 24px;
    margin-top: 24px;
    border-top: 0.8px solid #dadada;
    text-transform: uppercase;
    margin-bottom: 24px;
  }
  a {
    color: var(--main);
    font-weight: 600;
    font-size: 18px;
    display: block;
    margin-bottom: 24px;
    i {
      width: 32px;
    }
  }
`;
