import styled from "@emotion/styled";

export const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const Relative = styled.div`
  position: relative;
  .date {
    position: absolute;
    top: 10px;
    right: 0;
    z-index: 999;
  }
`;
