import styled from "@emotion/styled";

export const Wrapper = styled.div`
  label {
    display: flex;
    width: 150px;
    justify-content: flex-start;
    margin-top: 14px;
  }
  input {
    padding: 0;
    width: auto;
    margin-right: 12px;
    transform: translateY(1.5px);
  }
`;
