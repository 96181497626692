import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Flex,
  Wrapper,
  Avatar,
  Name,
  Droppdown,
  DropdownItem,
} from "./profile.e";

function Profile() {
  const navigate = useNavigate();
  const [dropdown, setDropdown] = useState(false);
  const { t } = useTranslation();
  return (
    <Flex>
      <Wrapper onClick={() => setDropdown(!dropdown)}>
        <Avatar>S</Avatar>
        <Name>
          <p>{localStorage.getItem("user").slice(4, 20)}</p>
          <span>{t("supplier")}</span>
        </Name>
        {dropdown && (
          <Droppdown>
            <DropdownItem
              onClick={() => {
                localStorage.clear();
                localStorage.removeItem("token");
                localStorage.removeItem("user");
                navigate(0);
                console.log(11);
              }}
            >
              <i className="fa-solid fa-arrow-right-from-bracket" />
              {t("logout")}
            </DropdownItem>
          </Droppdown>
        )}
      </Wrapper>
    </Flex>
  );
}

export default Profile;
