import { useState } from "react";
import i18next from "i18next";
import cookie from "js-cookie";
import { useTranslation } from "react-i18next";
import { Card, CardHead } from "../../components/common/global/global";
import { PageWrapper } from "../../components/common/global/global";
import Pagename from "../../components/common/pagename/page-name";
import { Wrapper } from "./settings.e";

function Settings() {
  const selected = cookie.get("i18next") || "en";
  const [lang, setLang] = useState(selected);
  const { t } = useTranslation();
  return (
    <PageWrapper>
      <Pagename text={t("settings")} />
      <Wrapper>
        <Card>
          <CardHead>
            <h6>{t("language")}</h6>
            <label>
              <input
                checked={lang === "en"}
                onChange={() => {
                  setLang("en");
                  i18next.changeLanguage("en");
                }}
                name="lang"
                type="radio"
              />
              English
            </label>
            <label>
              <input
                checked={lang === "ru"}
                onChange={() => {
                  setLang("ru");
                  i18next.changeLanguage("ru");
                }}
                name="lang"
                type="radio"
              />
              Русский
            </label>
          </CardHead>
        </Card>
      </Wrapper>
    </PageWrapper>
  );
}

export default Settings;
