import styled, { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  :root {
    --main: #315881;
    --green: #00cccb;
    --purple: #5b47fb;
    --orange: #ffbf95;
    --gray: #9A9FA5;
    --red: rgb(220, 53, 69);
    --blue: rgb(0, 184, 212);
    --light-gray: #6F767E;
    --thin-gray: rgb(131, 146, 165);
    --blue: rgb(1, 104, 250);
    --border-color: rgb(233, 241, 252);
  }
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Montserrat', sans-serif;
    user-select: none;
  }
  html {
    scroll-behavior: smooth;
  }
  body {
    background: rgba(26, 108, 225, 0.03);
  }
  input {
    outline: none;
    background-color: transparent;
    border: none;
    background: #EDEDED;
    border-radius: 12px;
    padding: 14px 15px;
    width: 100%;
    font-size: 16px;
  }
  label {
    color: var(--gray);
    font-weight: 400;
  }
  ul, li {
    list-style: none;
  }
  button {
    border: none;
    outline: none;
  }
  a {
    text-decoration: none;
  }
  h2 {
    font-weight: 500;
    font-size: 32px;
  }
  h3 {
    font-size: 1.53125rem;
  }
  h6 {
    font-size: 12px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    font-weight: 600;
    color: rgb(27, 46, 75);
    margin-bottom: 8px;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type=number] {
    -moz-appearance: textfield;
  }
  body {
    /* background: rgba(26, 108, 225, 0.03); */
    color: rgb(0, 23, 55);
    font-size: 0.875rem;
  }
  h4 {
    font-size: 1.5rem;
    font-weight: 600;
    margin: 15px 0;
    @media (max-width: 800px) {
      font-size: 16px;
    }
  }
  .gray {
    color: var(--gray);
  }
  .light-gray {
    color: var(--light-gray);
  }
  .error {
    font-size: 14px;
    color: #ed4337;
    margin: 4px 0;
    margin-bottom: 8px;
  }
  .rdrDefinedRangesWrapper {
    display: none !important;
  }
  .date {
    box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
  }
  table {
    width: 100%;
    font-size: 14px !important;
    line-height: 1.4 !important;
    font-weight: 500 !important;
    tr {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 11px 0;
      border-top: 1px solid rgb(233, 241, 252);
    }
    @media (max-width: 800px) {
      font-size: 12px;
    }
    th {
      text-align: left;
    }
  }
  @media (max-width: 1000px) {
    .responsiveTable tbody tr {
      border: 1px solid rgb(233,241,252);
      margin-bottom: 18px;
      td {
        margin: 12px 0;
        line-height: 1.5;
        width: 100% !important;
        color: #000;
      }
    }
  }
  &.red {
    color: var(--red)
  }
  &.green {
    color: var(--green);
  }
  &.purple {
    color: var(--purple);
  }
  &.gray {
    color: var(--gray);
  }
  &.w-500 {
    font-weight: 500;
  }
  &.w-600 {
    font-weight: 600;
  }
  &.w-700 {
    font-weight: 700;
  }
  &.mb-4 {
    margin-bottom: 4px;
  }
  &.s11 {
    font-size: 11px;
  }
`;

export const Container = styled.div`
  @media (max-width: 800px) {
    padding: 0 25px;
  }
`;

export const AppWrapper = styled.div`
  display: grid;
  grid-template-columns: 80px auto;
  @media (max-width: 800px) {
    grid-template-columns: 1fr;
  }
`;

export const PageWrapper = styled.div`
  padding: 45px 60px;
  width: auto;
  @media (max-width: 800px) {
    padding: 30px 20px;
  }
`;

export const Grid4 = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  margin-bottom: 25px;
  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    grid-gap: 0;
  }
`;

export const Card = styled.div`
  position: relative;
  overflow-wrap: break-word;
  background-color: rgb(255, 255, 255);
  background-clip: border-box;
  border: 1px solid transparent;
  border-radius: 4px;
  box-shadow: rgb(26 108 225 / 10%) 0px 0px 25px;
  margin-top: 25px;
  &.chart {
    height: 200px;
    pointer-events: none;
  }
  overflow: hidden;
`;

export const CardBody = styled.div`
  padding: 20px;
`;

export const CardHead = styled.div`
  padding: 15px 20px;
  h6 {
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    input {
      background-color: transparent;
      border: 1px solid var(--purple);
      padding: 6px 10px;
      font-size: 14px;
    }
  }
`;

export const Count = styled.div`
  h3 {
    font-weight: 500;
    margin-bottom: 8px;
    font-size: 30px;
  }
  p {
    color: rgb(131, 146, 165);
    font-size: 12px;
    span {
      font-weight: 500;
      color: rgb(16, 183, 89);
      i {
        margin-right: 6px;
        margin-left: 2px;
      }
    }
  }
`;

export const Absolute = styled.div`
  position: absolute;
  bottom: -65px;
  left: -10%;
  width: 120%;
  div {
    height: 30px !important;
  }
`;
