import { useState, useContext, useEffect } from "react";
import axios from "axios";
import InputMask from "react-input-mask";
import { AuthContext } from "../../context/auth";
import Button from "../../components/common/button/button";
import { Container } from "../../components/common/global/global";
import { Form, Wrapper, AuthContent, Phone } from "./auth.e";
import Logo from "../../components/common/logo/logo";

function Auth() {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [otpError, setOtpError] = useState("");
  const [otp, setOtp] = useState("");
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState("");
  const { setIsLogged } = useContext(AuthContext);

  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    if (step === 2) {
      let myInterval = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        }
        if (seconds === 0) {
          if (minutes === 0) {
            clearInterval(myInterval);
          } else {
            setMinutes(minutes - 1);
            setSeconds(59);
          }
        }
      }, 1000);
      return () => {
        clearInterval(myInterval);
      };
    }
    seconds === 0 && setStep(1);
  });

  const nextStep = () => {
    setLoading(true);
    setStep(2);
    if (phoneNumber.replace(/[^\d]/g, "").length !== 9) {
      setPhoneError("Неверный формат");
    } else {
      setPhoneError("");
      setLoading(true);
      // axios
      //   .post("https://dev.in1.uz/api/invan-supplier/supplier/login", {
      //     phone_number: `+998${phoneNumber.replace(/[^\d]/g, "")},`,
      //     headers: {
      //       "Content-Type": "application/json",
      //     },
      //   })
      //   .then((res) => {
      //     setLoading(false);
      //   })
      //   .catch((e) => {
      //     alert("Not valid");
      //     setLoading(false);
      //     setStep(1);
      //   });

      fetch("https://dev.in1.uz/api/invan-supplier/supplier/login", {
        method: "POST", // or 'PUT'
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          phone_number: `+998${phoneNumber.replace(/[^\d]/g, "")}`,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          setLoading(false);
        })
        .catch((error) => {
          alert("Not valid");
          setLoading(false);
          setStep(1);
        });
    }
  };

  const login = () => {
    setLoading(true);
    axios
      .post("https://dev.in1.uz/api/invan-supplier/supplier/verify", {
        phone_number: `+998${phoneNumber.replace(/[^\d]/g, "")}`,
        otp: otp,
      })
      .then((res) => {
        setLoading(false);
        if (res.statusText !== "OK") {
          setOtpError("Verification code is not valid");
        } else {
          setIsLogged(true);
          localStorage.setItem("user", res.data.phone_number);
          localStorage.setItem("token", res.data.token);
        }
      })
      .catch((e) => {
        setLoading(false);
        alert("Incorrect");
      });
  };

  return (
    <Container>
      <Wrapper>
        <AuthContent>
          <Logo />
          <h2 className="gray">Вход в аккуант</h2>
          <Form
            autoComplete="off"
            onSubmit={(e) => {
              e.preventDefault();
              console.log(11);
              step === 1 ? nextStep() : login();
            }}
          >
            <label htmlFor="phone">Введите номер телефона</label>
            <Phone>
              <span>
                <i className="fa-regular fa-phone"></i> +998
              </span>
              <InputMask
                mask="(99) 999-99-99"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              >
                {(inputProps) => (
                  <input
                    {...inputProps}
                    placeholder="-- --- -- --"
                    type="tel"
                  />
                )}
              </InputMask>
            </Phone>
            {phoneError && <p className="error">{phoneError}</p>}
            {step === 2 && seconds !== 0 && (
              <input
                className="anime"
                type="text"
                value={otp}
                maxLength={4}
                placeholder="Введите код подтверждение"
                onChange={(e) => setOtp(e.target.value)}
              />
            )}
            {otpError && <p className="error">{otpError}</p>}
            <Button
              loading={loading}
              onClick={() => (step === 1 ? nextStep() : login())}
              text={step === 1 ? "Отправить код" : "Войти в магазин"}
              color="primary"
              full
              disabled={phoneNumber.replace(/[^\d]/g, "").length !== 9}
            />
            {seconds !== 0 && (
              <span className="timer">
                You can resend after {minutes}:
                {seconds < 10 ? `0${seconds}` : seconds}
              </span>
            )}
          </Form>
        </AuthContent>
      </Wrapper>
    </Container>
  );
}

export default Auth;
