import axios from "axios";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Card, CardHead } from "../../components/common/global/global";
import { PageWrapper } from "../../components/common/global/global";
import Pagename from "../../components/common/pagename/page-name";
import { Flex, Radio, RadioButtons, Wrapper } from "./inventory.e";
import Loader from "../../components/common/loader/loader";
import Pagination from "../../components/common/pagination/pagination";

function Intentory() {
  const [page, setPage] = useState(1);
  const [data, setdata] = useState([]);
  const [sort, setSort] = useState("name");
  const [sortType, setSortType] = useState("1");
  const { t } = useTranslation();

  useEffect(() => {
    axios
      .get(
        `https://dev.in1.uz/api/invan-supplier/supplier/valuation?page=${page}&sort_by=${sort}&sort_type=${sortType}`,
        {
          headers: {
            Authorization: `${localStorage.getItem("token")}`,
            "content-type": "application/json",
            "Accept-version": "1.0.0",
          },
        }
      )
      .then((response) => {
        setdata(response.data);
      })
      .catch((error) => {
        alert(error?.message);
      });
  }, [page, sort, sortType]);

  return (
    <PageWrapper>
      {data.total && (
        <Pagename
          text={`${t("inventory")} (${data.total}  ${t("inventories")})`}
        />
      )}
      {!data.data && <Loader />}
      <Wrapper style={!data.data ? { opacity: 0 } : { opacity: 1 }}>
        <Card>
          <CardHead>
            <h6>
              {t("inventory")}
              {/* <input
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Search"
              /> */}
              <Flex>
                <RadioButtons>
                  <Radio
                    className={sort === "name" ? "active" : ""}
                    onClick={() => setSort("name")}
                  >
                    {t("by_name")}
                  </Radio>
                  <Radio
                    className={sort === "in_stock" ? "active" : ""}
                    onClick={() => setSort("in_stock")}
                  >
                    {t("by_stock")}
                  </Radio>
                </RadioButtons>
                <RadioButtons>
                  <Radio
                    className={sortType === "1" ? "active" : ""}
                    onClick={() => setSortType("1")}
                  >
                    {t("az")}
                  </Radio>
                  <Radio
                    className={sortType === "-1" ? "active" : ""}
                    onClick={() => setSortType("-1")}
                  >
                    {t("za")}
                  </Radio>
                </RadioButtons>
              </Flex>
            </h6>
            <div style={{ overflow: "auto" }}>
              <table>
                <thead>
                  <tr>
                    <th width="60%"> {t("document")}</th>
                    <th width="20%"> {t("price")}</th>
                    <th width="20%"> {t("stock")}</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.data?.map((i) => {
                    return (
                      <tr key={i?._id}>
                        <td width="60%" style={{ paddingRight: "18px" }}>
                          <p className="name">{i.name}</p>
                          <p className="gray">
                            {" "}
                            {t("sku")}: {i?.sku}
                          </p>
                        </td>
                        <td width="20%" className="green">
                          {Math.floor(i?.cost, 2).toLocaleString()}
                        </td>
                        <td
                          width="20%"
                          className={i.in_stock === 0 ? "red" : "purple"}
                        >
                          {i?.in_stock}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </CardHead>
        </Card>
      </Wrapper>
      {data.data && (
        <Pagination
          current={page}
          total={Math.round(data?.page_count)}
          next={() => setPage(page + 1)}
          prev={() => setPage(page - 1)}
          onChange={setPage}
        />
      )}
    </PageWrapper>
  );
}

export default Intentory;
