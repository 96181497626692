import { useTranslation } from "react-i18next";
import Button from "../button/button";
import { Wrapper, FlexContainer } from "./pagination.e";

function Pagination({ current, total, next, prev, onChange }) {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <FlexContainer>
        <Button
          onClick={prev}
          color="primary"
          text={<i className="fa-solid fa-angle-left" />}
          disabled={current === 1}
        />
        <p>
          {t("page")}{" "}
          <b>
            {current} of {total > 0 ? total : 1}
          </b>
        </p>
        <label>
          <input
            id="number"
            type="number"
            value={current}
            onChange={(e) => {
              let value = parseInt(e.target.value);
              if (value > total || value === 0) return;
              else return onChange(value);
            }}
          />
        </label>
        <Button
          onClick={next}
          color="primary"
          text={<i className="fa-solid fa-angle-right" />}
          disabled={current === total}
        />
      </FlexContainer>
    </Wrapper>
  );
}

export default Pagination;
