import { Wrapper } from "./button.e";

function Button({ text, full, color, loading, onClick, disabled }) {
  return (
    <Wrapper
      color={color}
      variant="contained"
      fullWidth={full}
      aria-label="button"
      disabled={loading || disabled}
      onClick={onClick}
    >
      {loading && <i className="fa fa-spinner fa-spin" />}
      {text}
    </Wrapper>
  );
}

export default Button;
