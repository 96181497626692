import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./i18/i18n";
import { AuthProvider } from "./context/auth";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <AuthProvider>
    <App />
  </AuthProvider>
);
