import { NavLink } from "react-router-dom";
import { LinkItem, Links, Wrapper } from "./menu.e";

function Menu() {
  return (
    <Wrapper>
      <Links>
        <NavLink
          to="/"
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          <LinkItem>
            <i className="fa-solid fa-chart-pie-simple" />
          </LinkItem>
        </NavLink>
        <NavLink
          to="/inventory"
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          <LinkItem>
            <i className="fa-solid fa-cube" />
          </LinkItem>
        </NavLink>
        <NavLink
          to="/sales-by-products"
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          <LinkItem>
            <i className="fa-solid fa-square-kanban" />
          </LinkItem>
        </NavLink>
        <NavLink
          to="/documents"
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          <LinkItem>
            <i className="fa-solid fa-file-lines" />
          </LinkItem>
        </NavLink>
        <NavLink
          to="/settings"
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          <LinkItem>
            <i className="fa-solid fa-gear" />
          </LinkItem>
        </NavLink>
      </Links>
    </Wrapper>
  );
}

export default Menu;
