import styled from "@emotion/styled";

export const Wrapper = styled.div`
  /* .name {
    width: 185px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  } */
  input {
    width: 200px;
  }
`;

export const RadioButtons = styled.div`
  display: flex;
  border-radius: 4px;
  overflow: hidden;
  margin-right: 30px;
  @media (max-width: 800px) {
    margin-right: 10px;
  }
`;

export const Radio = styled.div`
  padding: 5px 15px;
  border: 1px solid rgb(192, 204, 218);
  color: rgba(27, 46, 75, 0.7);
  font-size: 12px !important;
  text-transform: capitalize;
  border-radius: 4px;
  cursor: pointer;
  transition: 0.15s all ease-in-out;
  background-color: #fff;
  &.active {
    background-color: rgb(245, 246, 250);
  }
  &:hover {
    border-color: black;
  }
  &:last-child {
    margin-left: -4px;
  }
  @media (max-width: 800px) {
    padding: 3px 6px;
    font-size: 10px;
    &:last-child {
      margin-left: -2px;
    }
  }
`;

export const Flex = styled.div`
  display: flex;
`;
