import React from "react";
import { Link } from "react-router-dom";
import { Wrapper } from "./logo.e";
import LogoImg from "../../../assets/images/logo.svg";

function Logo({ forHeader }) {
  return (
    <Link to="/">
      <Wrapper className={forHeader ? "header" : ""}>
        <img src={LogoImg} alt="Invan" />
        <p>Invan</p>
      </Wrapper>
    </Link>
  );
}

export default Logo;
