import styled from "@emotion/styled";

export const Wrapper = styled.div`
  margin: 30px 0;
  font-size: 12px;
  .downloader {
    color: var(--purple);
    font-size: 20px;
  }
  .url {
    color: var(--blue);
    text-decoration: underline;
    cursor: pointer;
  }
`;
