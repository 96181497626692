import styled from "@emotion/styled";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  img {
    margin-right: 15px;
    width: 71px;
    height: 28px;
  }
  p {
    font-weight: 600;
    font-size: 20px;
    color: var(--main);
  }
  &.header {
    padding-left: 60px;
    p {
      font-size: 18px;
    }
    @media (max-width: 800px) {
      padding-left: 0px;
    }
  }
`;
