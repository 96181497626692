import { useContext } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AppWrapper, GlobalStyle } from "./components/common/global/global";
import Header from "./components/common/header/header";
import Menu from "./components/menu/menu";
import { AuthContext } from "./context/auth";

// Pages
import Auth from "./pages/auth/auth";
import Reports from "./pages/reports/reports";
import Documents from "./pages/documents/documents";
import Inventory from "./pages/inventory/inventory";
import Settings from "./pages/settings/settings";
import SalesByProducts from "./pages/sales-by-products/sales-by-products";

function App() {
  const { isLogged } = useContext(AuthContext);

  return (
    <>
      <BrowserRouter>
        <GlobalStyle />
        {isLogged ? (
          <>
            <Header />
            <AppWrapper>
              <Menu />
              <div>
                <Routes>
                  <Route path="/" element={<Reports />} />
                  <Route path="/documents" element={<Documents />} />
                  <Route path="/inventory" element={<Inventory />} />
                  <Route path="/settings" element={<Settings />} />
                  <Route
                    path="/sales-by-products"
                    element={<SalesByProducts />}
                  />
                </Routes>
              </div>
            </AppWrapper>
          </>
        ) : (
          <Auth />
        )}
      </BrowserRouter>
    </>
  );
}

export default App;
