import styled from "styled-components";

export const Wrapper = styled.button`
  border-radius: 12px !important;
  padding: 14px !important;
  background-color: var(--main);
  font-weight: 300;
  color: #fff;
  cursor: pointer;
  position: relative;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;
  &:disabled {
    opacity: 0.9;
    cursor: not-allowed;
  }
  &::after {
    content: "";
    transition: opacity 0.15s ease-in-out;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    opacity: 0;
    border-radius: 4px;
  }
  &:hover::after {
    opacity: 1;
  }
  .fa-spin {
    margin-left: -12px;
    margin-right: 12px;
    transform: translateY(-1px);
  }
`;
